
function UpdateEventFromQueryString(isDesktop, isActive_CurrentFutureTab) {
    // current-future tab
    refreshDropdowns(true, isActive_CurrentFutureTab);

    // past tab
    refreshDropdowns(false, isActive_CurrentFutureTab);

    function refreshDropdowns(isFor_CurrentFutureTab, isActive_CurrentFutureTab) {
        var append = isFor_CurrentFutureTab ? "" : "_2";
        var ddlVal = [];
        var ddlValpagesize = [];

        if ((isFor_CurrentFutureTab && isActive_CurrentFutureTab) || (isFor_CurrentFutureTab == false && isActive_CurrentFutureTab == false)) {
            ddlVal = getParamValueFrmKey('SortingField');
            ddlValpagesize = getParamValueFrmKey('pagesize');
        }

        if (ddlVal) {
            $("#custom4" + append).val(ddlVal);
            var userText = $("#custom4" + append + " option:selected").text();
            $('#oldSelectedval' + append).val($("#custom4" + append + " option:selected").val());
            $(".sortSel .main #ulcustom4" + append + " .stylish-select-left .stylish-select-right").text(userText);
        }
        else {
            var ddlName = 'custom4' + append;
            $(".sortSel #" + ddlName).prop('selectedIndex', 0);
            var userText = $('#' + ddlName).val();
            $(".sortSel .main #ul" + ddlName + " .stylish-select-left .stylish-select-right").text(userText);
            $('#oldSelectedval' + append).val($('#' + ddlName + '').val());
            $("#ul" + ddlName + " ul.listing li").removeClass("selected");
            $("#ul" + ddlName + " ul.listing li:first").addClass("selected");
        }

        if (ddlValpagesize) {
            if (isDesktop) {
                $("#ddlPageSize_Event" + append).val(ddlValpagesize);
                var userTextPagesize = $("#ddlPageSize_Event" + append + " option:selected").text();
                $('#oldSelectedPageSize' + append).val($("#ddlPageSize_Event" + append + " option:selected").val());
                $(".sortSel .main #ulddlPageSize_Event" + append + " .stylish-select-left .stylish-select-right").text(userTextPagesize);
            }
            else {
                $("#ddlPageSize_EventMob" + append).val(ddlValpagesize);
                var userTextPagesize = $("#ddlPageSize_EventMob" + append + " option:selected").text();
                $('#oldSelectedPageSize' + append).val($("#ddlPageSize_EventMob" + append + " option:selected").val());
                $(".sortSel .main #ulddlPageSize_EventMob" + append + " .stylish-select-left .stylish-select-right").text(userTextPagesize);
            }
        }
        else {
            if (isDesktop) {
                var ddlName = 'ddlPageSize_Event' + append;
                $(".sortSel #" + ddlName).prop('selectedIndex', 0);
                var userText = $('#' + ddlName).val();
                $(".sortSel .main #ul" + ddlName + " .stylish-select-left .stylish-select-right").text(userText);
                $('#oldSelectedPageSize' + append).val($('#' + ddlName + '').val());
                $("#ul" + ddlName + " ul.listing li").removeClass("selected");
                $("#ul" + ddlName + " ul.listing li:first").addClass("selected");
            }
            else {
                var ddlName = 'ddlPageSize_EventMob' + append;
                $(".sortSel #" + ddlName).prop('selectedIndex', 0);
                var userText = $('#' + ddlName).val();
                $(".sortSel .main #ul" + ddlName + " .stylish-select-left .stylish-select-right").text(userText);
                $('#oldSelectedPageSize' + append).val($('#' + ddlName + '').val());
                $("#ul" + ddlName + " ul.listing li").removeClass("selected");
                $("#ul" + ddlName + " ul.listing li:first").addClass("selected");
            }
        }
    }
}

(function ($) {
    var plugin_event = this;
    plugin_event.settings = {};
    //Start 
    plugin_event.searchString = "";
    plugin_event.eventLocations = "";
    plugin_event.startDate = "";
    plugin_event.endDate = "";
    plugin_event.indexName = "";
    plugin_event.filterName = "";
    plugin_event.filterId = "";
    plugin_event.filterValue = "";
    plugin_event.pageNum = "";
    plugin_event.eventType = "";
    plugin_event.isCleCredit = "";
    plugin_event.isResetFields = "";
    plugin_event.isFewerOptionOpen = "false";
    plugin_event.loadResults = true;

    plugin_event.totalPages = 0;
    plugin_event.loadUpcomingEvents = "";
    plugin_event.loadFacets = true;
    plugin_event.loadContent = true;
    plugin_event.groupClicked = false;
    plugin_event.megaMenuFilter = false;
    plugin_event.settings.groupName = "";
    plugin_event.settings.listUrl = "";
    plugin_event.settings.listType = "completelistLocation";


    plugin_event.pagingInfo = {};
    plugin_event.pagingInfo.pageSize = 5;
    plugin_event.pagingInfo.currentPage = 1;
    plugin_event.pagingInfo.isLoadPagination = true;

    plugin_event.pagingInfo.pagingLastCount = "";
    plugin_event.pagingInfo.pagingFirstCount = "";
    plugin_event.pagingInfo.pagingNextCount = "";
    plugin_event.pagingInfo.pagingPreviousCount = "";

    plugin_event.pagingInfo.isLastClick = false;
    plugin_event.pagingInfo.isFirstClick = false;
    plugin_event.pagingInfo.isNextClick = false;
    plugin_event.pagingInfo.isPreviousClick = false;

    plugin_event.pagingInfo2 = {};
    plugin_event.pagingInfo2.pageSize = 5;
    plugin_event.pagingInfo2.currentPage = 1;
    plugin_event.pagingInfo2.isLoadPagination = true;

    plugin_event.pagingInfo2.pagingLastCount = "";
    plugin_event.pagingInfo2.pagingFirstCount = "";
    plugin_event.pagingInfo2.pagingNextCount = "";
    plugin_event.pagingInfo2.pagingPreviousCount = "";

    plugin_event.pagingInfo2.isLastClick = false;
    plugin_event.pagingInfo2.isFirstClick = false;
    plugin_event.pagingInfo2.isNextClick = false;
    plugin_event.pagingInfo2.isPreviousClick = false;

    plugin_event.processingRequest = false;
    plugin_event.datasource = {};

    plugin_event.isActiveTab_CurrentFuture = true; // New Events Page

    $.eventSearch = function (element, options) {
        /* defaults */
        var defaults = {
            pageNumber: "#pageNum",
            pageSize: "10",
            resultscontainer: "#eventsResult",
            itemId: "",
            filtersDiv: "#eventSearchFilters",
            loaderDiv: "#divProcessingEvent",
            lastAlphaNav: '',
            pagingContainer: "#pagingContainerPeople, #pagingContainerPeople2",
            pageHeader: "#peopleListingHeader",
            pageUrl: "",
            keywordDefaultValue: "",
            locationDefaultValue: "",
            practiceDefaultValue: ""



        };

        function ResetPagingInfo() {
            plugin_event.pagingInfo.currentPage = 1;
            plugin_event.pagingInfo.isLoadPagination = true;
            plugin_event.pagingInfo.pagingLastCount = "";
            plugin_event.pagingInfo.pagingFirstCount = "";
            plugin_event.pagingInfo.pagingNextCount = "";
            plugin_event.pagingInfo.pagingPreviousCount = "";
            plugin_event.pagingInfo.isLastClick = false;
            plugin_event.pagingInfo.isFirstClick = false;
            plugin_event.pagingInfo.isNextClick = false;
            plugin_event.pagingInfo.isPreviousClick = false;
            plugin_event.pagingInfo.isViewAllClick = false;

            plugin_event.pagingInfo2.currentPage = 1;
            plugin_event.pagingInfo2.isLoadPagination = true;
            plugin_event.pagingInfo2.pagingLastCount = "";
            plugin_event.pagingInfo2.pagingFirstCount = "";
            plugin_event.pagingInfo2.pagingNextCount = "";
            plugin_event.pagingInfo2.pagingPreviousCount = "";
            plugin_event.pagingInfo2.isLastClick = false;
            plugin_event.pagingInfo2.isFirstClick = false;
            plugin_event.pagingInfo2.isNextClick = false;
            plugin_event.pagingInfo2.isPreviousClick = false;
            plugin_event.pagingInfo2.isViewAllClick = false;
        }

        function ResetEventFields() {
            $("#event-search").val(plugin_event.settings.keywordDefaultValue);
            $("#event-location-search").val(plugin_event.settings.locationDefaultValue);
            $("#event-practice-search").val(plugin_event.settings.practiceDefaultValue);
            $('#start_date-search').val($('#start_date-search').data('defaultvalue'));
            $('#end_date-search').val($('#end_date-search').data('defaultvalue'));
            $("#end_date-search").datepicker("option", "minDate", null);
            $("#start_date-search").datepicker("option", "maxDate", null);
            allCheckBoxChange(1);
            $('#clecheckbox').removeAttr("checked");
            $('#clecheckbox').parent().css("background-position", "center 0px");
            plugin_event.loadUpcomingEvents = "false";
        }

        function SetActiveTab() {
            plugin_event.isActiveTab_CurrentFuture = true; // New Events Page
        }

        var $element = $(element), element = element;


        /* models */
        var group = function (eventTitle, eventDate, eventLocation, participants, eventType, eventUrl) {
            this.Title = eventTitle,
                this.EventType = eventType,
                this.EventLocation = eventLocation,
                this.Participants = participants,
                this.Url = eventUrl;
            this.EventDate = eventDate;
        };

        var totalRecordCount = function (n) {
            this.totalRecord = n;
        };

        var totalRecordCount2 = function (n) {
            this.totalRecord2 = n;
        };

        var pagefacet = function (n, c, isLarge) {
            this.pageNo = n;
            this.applyCss = c;
            this.isLargeNumber = isLarge;
        };

        /* init function */
        plugin_event.init = function () {

            var isCleQsVal = getParamValueFrmKey("iscle");

            plugin_event.settings = $.extend({}, defaults, options);

            $(window).bind("pageshow", function (event) {
                if (event.originalEvent.persisted) {
                    window.location.reload();
                }
            });

            // New Events Page
            /* get selected tab via QS */
            if (getActiveTabViaQS() == "past") {
                plugin_event.isActiveTab_CurrentFuture = false;
            }
            else { // current-future
                plugin_event.isActiveTab_CurrentFuture = true;
            }


            var upcomingEvents = "";
            upcomingEvents = $.urlParam('loadupcomingevents');
            if ((upcomingEvents != null && upcomingEvents != "")) {
                plugin_event.loadUpcomingEvents = upcomingEvents;
            }
            else {
                plugin_event.loadUpcomingEvents = "true";
            }



            var searchString = $.urlParam('searchstring');
            if (searchString != null && searchString != "") {
                $("#event-search").val(decodeURIComponent(searchString.split('+').join(' ')));
                plugin_event.searchString = searchString;
            } else {
                $("#event-search").val(plugin_event.settings.keywordDefaultValue);
            }

            var eventLocations = $.urlParam('eventlocations');
            if (eventLocations != null && eventLocations != "") {
                $('#event-location-search').val(decodeURIComponent(eventLocations));
                plugin_event.eventLocations = eventLocations;
            }
            else {
                $('#event-location-search').val(plugin_event.settings.locationDefaultValue);
            }

            var startDate = $.urlParam('start_date');
            if (startDate != null && startDate != "") {
                plugin_event.startDate = startDate;
            }
            else { $('#start_date-search').val($('#start_date-search').data('defaultvalue')); }

            var endDate = $.urlParam('end_date');
            if (endDate != null && endDate != "") {
                plugin_event.endDate = endDate;
            }
            else { $('#end_date-search').val($('#end_date-search').data('defaultvalue')); }

            var filterName = $.urlParam('filtername');
            var filterId = $.urlParam('filterid');
            var filterValue = $.urlParam('filtervalue');
            if (filterName != null && filterName != "" && filterId != null && filterId != "") {
                plugin_event.filterName = filterName;
                plugin_event.filterId = filterId;
                $("#event-practice-search").val(decodeURIComponent(filterValue));
                $("#event-practice-search").attr("filter-indexname", filterName);
                $("#event-practice-search").attr("filter-indexid", filterId);
                $("#event-practice-search").attr("filter-indexvalue", filterValue);

            }
            if (filterValue != null && filterValue != "") {
                $("#event-practice-search").val(decodeURIComponent(filterValue));
                plugin_event.filterValue = $("#event-practice-search").val();
            } else { $("#event-practice-search").val(plugin_event.settings.practiceDefaultValue); }
            var pageNum = $.urlParam('pagenum');

            if (pageNum != null && pageNum != "") {
                plugin_event.pageNum = pageNum;
            }


            var openFewerOptions = $.urlParam('openfeweroptions');
            if (openFewerOptions != null && openFewerOptions == "true") {
                if (!$(".fewerOption a").hasClass("expand")) {
                    $(".fewerOption a").parent().next(".hiddenFewerForm").slideDown();
                    $(".fewerOption a").addClass("expand");

                    var hideText = $(".fewerOption a").attr("data-hide");
                    $(".fewerOption a").text(hideText);
                }
            }



            var eventType = $.urlParam('event_type_s');
            if (eventType != null && eventType != "") {
                if (plugin_event.loadUpcomingEvents != "true") {
                    //if (!$(".fewerOption a").hasClass("expand")) {
                    //    $(".fewerOption a").parent().next(".hiddenFewerForm").slideDown();
                    //    $(".fewerOption a").addClass("expand");

                    //    var hideText = $(".fewerOption a").attr("data-hide");
                    //    $(".fewerOption a").text(hideText);
                    //}
                }

                var ddlName = 'custom4';
                $(".sortSel #" + ddlName).prop('selectedIndex', 0);
                var userText = $('#' + ddlName).val();
                $(".sortSel .main #ul" + ddlName + " .stylish-select-left .stylish-select-right").text(userText);
                $('#oldSelectedval').val($('#' + ddlName + '').val());
                $("#ul" + ddlName + " ul.listing li").removeClass("selected");
                $("#ul" + ddlName + " ul.listing li:first").addClass("selected");

                var ddlName = 'custom4_2';
                $(".sortSel #" + ddlName).prop('selectedIndex', 0);
                var userText = $('#' + ddlName).val();
                $(".sortSel .main #ul" + ddlName + " .stylish-select-left .stylish-select-right").text(userText);
                $('#oldSelectedval_2').val($('#' + ddlName + '').val());
                $("#ul" + ddlName + " ul.listing li").removeClass("selected");
                $("#ul" + ddlName + " ul.listing li:first").addClass("selected");

                allCheckBoxChange(0);
                var allEventTypes = eventType.split(",");
                for (var i = 0; i < allEventTypes.length; i++) {
                    selectCheckBoxFromDataAttr(allEventTypes[i]);
                }

            }

            var isCleCredit = $.urlParam('param_is__cle_event_b');
            if (isCleCredit != null && isCleCredit == "true") {
                $('#clecheckbox').prop("checked", true);
                $('#clecheckbox').parent().css("background-position", "center -40px");
            }

            var pagingInfo = [];

            if (plugin_event.isActiveTab_CurrentFuture) {
                pagingInfo = plugin_event.pagingInfo; // current-future tab
            }
            else {
                pagingInfo = plugin_event.pagingInfo2; // past tab
            }

            var isNextClick = $.urlParam('isNextClick');

            if (isNextClick != null && isNextClick == "true") {

                plugin_event.loadResults = false;
                pagingInfo.isLoadPagination = true;
                pagingInfo.isNextClick = true;
                pagingInfo.isLastClick = false;
                pagingInfo.isFirstClick = false;
                pagingInfo.isPreviousClick = false;
                var pagingNextCount = $.urlParam('pagingNextCount');
                if (pagingNextCount != null) {
                    pagingInfo.pagingNextCount = pagingNextCount;
                    pagingInfo.currentPage = pagingNextCount;

                }
            }


            var isPrevClick = $.urlParam('isPreviousClick');

            if (isPrevClick != null && isPrevClick == "true") {
                plugin_event.loadResults = false;
                pagingInfo.isLoadPagination = true;
                pagingInfo.isNextClick = false;
                pagingInfo.isLastClick = false;
                pagingInfo.isFirstClick = false;
                pagingInfo.isPreviousClick = true;
                var pagingPrevCount = $.urlParam('pagingPreviousCount');
                if (pagingPrevCount != null) {
                    pagingInfo.pagingPreviousCount = pagingPrevCount;
                    pagingInfo.currentPage = pagingPrevCount;
                }

            }


            var isFirstClick = $.urlParam('isFirstClick');

            if (isFirstClick != null && isFirstClick == "true") {
                plugin_event.loadResults = false;
                pagingInfo.isLoadPagination = true;
                pagingInfo.isFirstClick = true;
                pagingInfo.isLastClick = false;
                pagingInfo.isNextClick = false;
                pagingInfo.isPreviousClick = false;
                var pagingFirstCount = $.urlParam('pagingFirstCount');
                if (pagingFirstCount != null) {
                    pagingInfo.pagingFirstCount = pagingFirstCount;
                    pagingInfo.currentPage = pagingFirstCount;
                }

            }


            var isLastClick = $.urlParam('isLastClick');

            if (isLastClick != null && isLastClick == "true") {
                pagingInfo.isLoadPagination = true;
                pagingInfo.isLastClick = true;
                pagingInfo.isFirstClick = false;
                pagingInfo.isNextClick = false;
                pagingInfo.isPreviousClick = false;
                var pagingLastCount = $.urlParam('pagingLastCount');
                if (pagingLastCount != null) {
                    pagingInfo.pagingLastCount = pagingLastCount;
                    pagingInfo.currentPage = pagingLastCount;
                }

            }


            plugin_event.datasource.vm = {
                facets: ko.observableArray([]),
                searchGroups: ko.observableArray([]),
                searchGroups2: ko.observableArray([]),
                facetGroups: ko.observableArray([]),
                total: ko.observable(""),
                /* if there spell check is true */
                showResults: ko.observable(""),
                searchText: ko.observable(""),
                /* for paging */
                pagingFacets: ko.observableArray([]),
                pagingFacets2: ko.observableArray([]),
                TotalRecordCounts: ko.observableArray([]),
                TotalRecordCounts2: ko.observableArray([]),
                pageLastNo: ko.observable(""),
                pageFirstNo: ko.observable(""),
                loadPageData: plugin_event.loadPagingResult,
                PageFirstClick: plugin_event.pgFirstclick,
                PageLastClick: plugin_event.pgLastclick,
                PageNextClick: plugin_event.pgNextclick,
                PagePreviousClick: plugin_event.pgPreviousclick,
                PageViewAllClick: plugin_event.pgViewAllClick,
                PageBackToPaginationClick: plugin_event.pgBackToPaginationClick
            };


            ko.applyBindings(plugin_event.datasource.vm);
            var isDesktop = true;
            if ($(window).width() < 480 || $(window).height() < 480) {
                // current-future tab
                $('#divPageSizeWeb').hide();
                $('#divPageSizeMob').show();
                $('#oldSelectedPageSize').val($('#ddlPageSize_EventMob').val());

                // past tab
                $('#divPageSizeWeb_2').hide();
                $('#divPageSizeMob_2').show();
                $('#oldSelectedPageSize_2').val($('#ddlPageSize_EventMob_2').val());
                isDesktop = false;
            }

            $(document).on("click", ".pageno", function () {
                $(".pageno").removeClass("selected");
                $(this).addClass("selected");
                ResetPagingInfo();
                var pageNo = $(this).text();
                location.href = getPageUrl(false, $.trim(pageNo));

            });




            $("#btnEventSearch").click(function () {
                if (typeof ga !== "undefined") {
                    ga('send', 'event', 'Event', 'Search', $('#event-search').val());
                }
                $("#btnEventSearch").focus();
                ResetPagingInfo();
                if ($(".fewerOption a").hasClass("expand")) {
                    plugin_event.isFewerOptionOpen = "true";
                } else {
                    plugin_event.isFewerOptionOpen = "false";
                }
                location.href = getPageUrl(false, 1);

            });


            $(".eventsUpcomingList").click(function (event) {
                //ResetEventFields();
                //$("#divFilterYear select").prop('selectedIndex', 1);

                // plugin_event.datasource.vm.searchGroups.removeAll();

                ResetPagingInfo();
                //var ddlName = 'custom4';
                //$(".sortSel #" + ddlName).prop('selectedIndex', 0);
                //var userText = $('#' + ddlName).val();
                //$(".sortSel .main #ul" + ddlName + " .stylish-select-left .stylish-select-right").text(userText);
                //$('#oldSelectedval').val($('#' + ddlName + '').val());
                //$("#ul" + ddlName + " ul.listing li").removeClass("selected");
                //$("#ul" + ddlName + " ul.listing li:first").addClass("selected");
                allCheckBoxChange(1);
                location.href = getPageUrl(true, 1);
                $(".pub-search-area").show();

            });


            $(".eventsTypeList").click(function (event) {
                ResetEventFields();
                plugin_event.isFewerOptionOpen = "true";
                plugin_event.isResetFields = true;
                var id = $(this).attr("filter-IndexId");
                if (id && id != '') {
                    if (!$(".fewerOption a").hasClass("expand")) {
                        $(".fewerOption a").parent().next(".hiddenFewerForm").slideDown();
                        $(".fewerOption a").addClass("expand");
                        // var showText = $(".fewerOption a").attr("data-show");
                        var hideText = $(".fewerOption a").attr("data-hide");
                        $(".fewerOption a").text(hideText);
                    }

                    allCheckBoxChange(0);
                    selectCheckBoxFromDataAttr(id)
                }

                ResetPagingInfo();

                location.href = getPageUrl(false, 1);

                $(".pub-search-area").show();


            });

            $('.cleTypes input[type="checkbox"]').click(function () {
                if ($(this).prop("checked")) {
                    $(this).removeAttr('checked');
                    $(this).parent().css("background-position", "center 0px");
                } else {
                    $(this).prop("checked", true);
                    $(this).parent().css("background-position", "center -40px");
                }
            });
            $(".cleEventTypeList").click(function (event) {
                ResetEventFields();
                allCheckBoxChange(1);
                $('#clecheckbox').prop("checked", true);
                $('#clecheckbox').parent().css("background-position", "center -40px");

                ResetPagingInfo();
                location.href = getPageUrl(false, 1);
                $(".pub-search-area").show();


            });


            $(".cleEventTypeList").click(function (event) {
                ResetEventFields();
                allCheckBoxChange(1);
                ResetPagingInfo();
                $('#clecheckbox').prop("checked", true);
                $('#clecheckbox').parent().css("background-position", "center -40px");
                location.href = getPageUrl(false, 1);
                $(".pub-search-area").show();
            });



            if (isCleQsVal) {
                if (isCleQsVal == 1) {
                    allCheckBoxChange(1);
                    if (!$(".fewerOption a").hasClass("expand")) {
                        $(".fewerOption a").parent().next(".hiddenFewerForm").slideDown();
                        $(".fewerOption a").addClass("expand");
                        var showText = $(".fewerOption a").attr("data-show");
                        var hideText = $(".fewerOption a").attr("data-hide");
                        $(".fewerOption a").text(hideText);
                    }
                    $('#clecheckbox').prop("checked", true);
                    $('#clecheckbox').parent().css("background-color", "red");

                }
            }
            $("#btnEventSearch").focus();

            var pageNextCount = $.urlParam('pagingNextCount');
            var pagePrevCount = $.urlParam('pagingPreviousCount');
            var pageFirstCount = $.urlParam('pagingFirstCount');
            var pageLastCount = $.urlParam('pagingLastCount');


            var currPage = 1;

            if (pageNextCount != null) {
                currPage = pageNextCount;
            }
            else if (pagePrevCount != null) {
                currPage = pagePrevCount;
            }
            else if (pageFirstCount != null) {
                currPage = pageFirstCount;
            }
            else if (pageLastCount != null) {
                currPage = pageLastCount;
            }

            UpdateEventFromQueryString(isDesktop, plugin_event.isActiveTab_CurrentFuture);

            checkDataDefault('event-search');
            checkDataDefault('event-location-search');
            checkDataDefault('start_date-search');
            checkDataDefault('end_date-search');
            checkDataDefault('event-practice-search');

            plugin_event.loadSearchResults(currPage, "");

            $(".pub-search-area").show();
            //end

            // current future tab
            setDropdownsOnChange(true);

            // past tab
            setDropdownsOnChange(false);

            function setDropdownsOnChange(isFor_CurrentFutureTab) {
                var append = isFor_CurrentFutureTab ? "" : "_2";

                $("#custom4" + append).change(function () {
                    var oldSelectedval = $('#oldSelectedval' + append).val();
                    if (oldSelectedval != $('#custom4' + append).val()) {
                        if ($(".fewerOption a").hasClass("expand")) {
                            plugin_event.isFewerOptionOpen = "true";
                        } else {
                            plugin_event.isFewerOptionOpen = "false";
                        }
                        $('#oldSelectedval' + append).val($('#custom4' + append).val());
                        plugin_event.settings.groupName = "";
                        ResetPagingInfo();
                        location.href = getPageUrl(plugin_event.loadUpcomingEvents, 1);

                    }
                });

                $("#ddlPageSize_Event" + append).change(function () {
                    var oldSelectedval = $('#oldSelectedPageSize' + append).val();
                    if (oldSelectedval != $('#ddlPageSize_Event' + append).val()) {
                        if ($(".fewerOption a").hasClass("expand")) {
                            plugin_event.isFewerOptionOpen = "true";
                        } else {
                            plugin_event.isFewerOptionOpen = "false";
                        }
                        $('#oldSelectedPageSize' + append).val($('#ddlPageSize_Event' + append).val());
                        plugin_event.settings.groupName = "";
                        ResetPagingInfo();
                        location.href = getPageUrl(plugin_event.loadUpcomingEvents, 1);
                    }
                });

                $("#ddlPageSize_EventMob" + append).change(function () {
                    var oldSelectedval = $('#oldSelectedPageSize' + append).val();
                    if (oldSelectedval != $('#ddlPageSize_EventMob' + append).val()) {
                        $('#oldSelectedPageSize' + append).val($('#ddlPageSize_EventMob' + append).val());
                        plugin_event.settings.groupName = "";
                        ResetPagingInfo();
                        location.href = getPageUrl(plugin_event.loadUpcomingEvents, 1);
                    }
                });
            }


        };

        //plugin_event.loadPagingResult = function (data, event) {
        //    plugin_event.pagingInfo.isLoadPagination = false;
        //    plugin_event.loadFacets = false;
        //    plugin_event.pagingInfo.isFirstClick = false;
        //    plugin_event.pagingInfo.isLastClick = false;
        //    plugin_event.pagingInfo.isNextClick = false;
        //    plugin_event.pagingInfo.isPreviousClick = false;
        //    plugin_event.pagingInfo.currentPage = data.pageNo;
        //    plugin_event.loadSearchResults(data.pageNo, "");


        //};

        function setPagingInfo_Events(pagingInfo, num_str) {

            $(document).on("click", "#pagLastBtn" + num_str, function () {
                pagingInfo.isLoadPagination = true;
                pagingInfo.isLastClick = true;
                pagingInfo.isFirstClick = false;
                pagingInfo.isNextClick = false;
                pagingInfo.isPreviousClick = false;
                pagingInfo.currentPage = pagingInfo.pagingLastCount;
                location.href = getPageUrl(false, pagingInfo.pagingLastCount);

            });


            $(document).on("click", "#pagFirstBtn" + num_str, function () {
                pagingInfo.isLoadPagination = true;
                pagingInfo.isFirstClick = true;
                pagingInfo.isLastClick = false;
                pagingInfo.isNextClick = false;
                pagingInfo.isPreviousClick = false;
                pagingInfo.currentPage = pagingInfo.pagingFirstCount;
                location.href = getPageUrl(false, pagingInfo.pagingFirstCount);

            });


            $(document).on("click", "#pagNextBtn" + num_str, function () {

                pagingInfo.isLoadPagination = true;
                pagingInfo.isNextClick = true;
                pagingInfo.isLastClick = false;
                pagingInfo.isFirstClick = false;
                pagingInfo.isPreviousClick = false;
                pagingInfo.currentPage = pagingInfo.pagingNextCount;
                location.href = getPageUrl(false, pagingInfo.pagingNextCount);

            });

            $(document).on("click", "#pagPrevBtn" + num_str, function () {
                pagingInfo.isLoadPagination = true;
                pagingInfo.isNextClick = false;
                pagingInfo.isLastClick = false;
                pagingInfo.isFirstClick = false;
                pagingInfo.isPreviousClick = true;
                pagingInfo.currentPage = pagingInfo.pagingPreviousCount;
                location.href = getPageUrl(false, pagingInfo.pagingPreviousCount);

            });

            plugin_event.pgPreviousclick = function (data, event) {
                plugin_event.datasource.vm.searchGroups.removeAll();
                plugin_event.loadFacets = false;
                pagingInfo.isLoadPagination = true;
                pagingInfo.isNextClick = false;
                pagingInfo.isLastClick = false;
                pagingInfo.isFirstClick = false;
                pagingInfo.isPreviousClick = true;
                pagingInfo.currentPage = pagingInfo.pagingPreviousCount;
                plugin_event.loadSearchResults(pagingInfo.pagingPreviousCount, "");
            };
        }

        setPagingInfo_Events(plugin_event.pagingInfo, "");
        setPagingInfo_Events(plugin_event.pagingInfo2, "2");


        plugin_event.loadSearchResults = function (currentPagenum, groupName) {
            if (!plugin_event.processingRequest) {
                $(plugin_event.settings.loaderDiv).show();
                $(plugin_event.settings.pagingContainer).hide();
                $(plugin_event.settings.resultscontainer).hide();
                $(plugin_event.settings.pageHeader).hide();

                $.getJSON(getServiceUrl(currentPagenum, groupName), function (data) {
                    if ((data.SearchGroups == null || data.SearchGroups.length == 0) && (data.SearchGroups2 == null || data.SearchGroups2.length == 0)) { // all tabs have no result
                        $(".no-result-found").show();
                        $("#eventsResult").hide();

                        $(plugin_event.settings.loaderDiv).hide();
                        $(plugin_event.settings.resultscontainer).hide();
                        $(plugin_event.settings.pagingContainer).hide();
                        $(plugin_event.settings.pageHeader).hide();
                        if ($(window).width() < 500) {
                            $('html, body').animate({
                                'scrollTop': $('.no-result-found').offset().top - 160
                            });
                        } else {
                            $('html, body').animate({
                                'scrollTop': $('.no-result-found').offset().top - 250
                            });

                        }

                        $("#event-search-tabs").tabs({ // initialize tabs with all are disabled
                            disabled: [0, 1]
                        });
                    }
                    else { // either of the tabs has result
                        $(".no-result-found").hide();
                        $("#eventsResult").show();

                        if (data.SearchGroups == null || data.SearchGroups.length == 0) { // current-future tab has NO values
                            plugin_event.datasource.vm.TotalRecordCounts.removeAll();
                            $("#event-search-tabs").tabs({ // initialize tabs where the past tab is active, current-future tab is disabled
                                active: 1,
                                disabled: [0]
                            });
                        }
                        else if (data.SearchGroups2 == null || data.SearchGroups2.length == 0) { // past tab has NO values
                            plugin_event.datasource.vm.TotalRecordCounts2.removeAll();
                            $("#event-search-tabs").tabs({ // initialize tabs where the current-future tab is active, past tab is disabled
                                active: 0,
                                disabled: [1]
                            });
                        }
                        else { // all tabs are available
                            var activeTab = plugin_event.isActiveTab_CurrentFuture ? 0 : 1;

                            $("#event-search-tabs").tabs({ // initialize tabs where the current-future tab is active
                                active: activeTab
                            });
                        }

                        // determine if there is tab with no result
                        if ($("#event-search-tabs .ui-tabs-nav .ui-state-default").hasClass("ui-state-disabled")) {
                            $("#event-search-tabs .ui-tabs-nav").addClass("has-disabled-tab");
                        }

                        if (data.TotalRecords != null) {
                            plugin_event.datasource.vm.TotalRecordCounts.removeAll();
                            plugin_event.datasource.vm.TotalRecordCounts.push(new totalRecordCount(data.TotalRecords));
                        }

                        if (data.TotalRecords2 != null) {
                            plugin_event.datasource.vm.TotalRecordCounts2.removeAll();
                            plugin_event.datasource.vm.TotalRecordCounts2.push(new totalRecordCount2(data.TotalRecords2));
                        }

                        // FUNCTION: setPagination
                        // PARAMETERS:
                        //  searchGroups = data.SearchGroups
                        //  pSearchGroups = plugin_event.datasource.vm.searchGroups
                        //  personPaging = data.PersonPaging
                        //  pagingFacets = plugin_event.datasource.vm.pagingFacets
                        //   pagingInfo = plugin_event.pagingInfo
                        //  num_str = number to append on the element

                        // current-future tab
                        setPagination(data.SearchGroups, plugin_event.datasource.vm.searchGroups, data.PersonPaging, plugin_event.datasource.vm.pagingFacets, plugin_event.pagingInfo, "");

                        // past tab
                        setPagination(data.SearchGroups2, plugin_event.datasource.vm.searchGroups2, data.PersonPaging2, plugin_event.datasource.vm.pagingFacets2, plugin_event.pagingInfo2, "2");

                        function setPagination(searchGroups, pSearchGroups, personPaging, pagingFacets, pagingInfo, num_str) {
                            if (searchGroups == null || searchGroups.length == 0) {
                                return;
                            }

                            //push people data
                            if (personPaging.IsPaginationVisible) {
                                $("#pagingContainerPeople" + num_str).show();

                                //Push Page Number
                                if (pagingInfo.isLoadPagination) {
                                    //if exists then remove first
                                    pagingFacets.removeAll();
                                    $.each(personPaging.PageNumbersFacets, function (i, f) {
                                        var isLarge = false;
                                        if (f > 99) {
                                            isLarge = true;
                                        }
                                        if (plugin_event.pageNum != null && plugin_event.pageNum == f) {
                                            pagingFacets.push(new pagefacet(f, 2, isLarge));
                                        } else {
                                            pagingFacets.push(new pagefacet(f, 0, isLarge));
                                        }

                                    });

                                    //set last and first page number
                                    pagingInfo.pagingLastCount = personPaging.PageLastNumber;
                                    pagingInfo.pagingFirstCount = personPaging.PageFirstNumber;

                                    //set last and previous index
                                    pagingInfo.pagingNextCount = personPaging.PageNextNumber;
                                    pagingInfo.pagingPreviousCount = personPaging.PagePreviousNumber;

                                    //for set next and previous cursor

                                    if (personPaging.IsNextCursorVisible) {
                                        $('#pagNextBtn' + num_str).show();
                                        $('#pagLastBtn' + num_str).show();
                                    } else {
                                        $('#pagNextBtn' + num_str).hide();
                                        $('#pagLastBtn' + num_str).hide();
                                    }

                                    if (personPaging.IsPreviousCursorVisible) {
                                        $('#pagFirstBtn' + num_str).show();
                                        $('#pagPrevBtn' + num_str).show();
                                    } else {
                                        $('#pagFirstBtn' + num_str).hide();
                                        $('#pagPrevBtn' + num_str).hide();
                                    }
                                }

                            } else {
                                $("#pagingContainerPeople" + num_str).hide();
                            }

                            $.each(searchGroups, function (i, f) {
                                pSearchGroups.push(new group(f.Title, f.EventDate, f.EventLocation, f.Participants, f.EventType, f.Url));

                            });

                            $(plugin_event.settings.loaderDiv).hide();
                            if ($(window).width() < 500) {
                                $('html, body').animate({
                                    'scrollTop': $('.event-search-result-wrapper').offset().top - 170
                                });
                            } else {
                                $('html, body').animate({
                                    'scrollTop': $('.event-search-result-wrapper').offset().top - 200
                                });

                            }
                        }
                    }
                });
            }

        }

        function GetPushStringFromIdAndValue(jDivObj) {
            var filterID = $(jDivObj).attr('filter-IndexId');
            var filterName = $(jDivObj).attr('filter-indexname');
            var ReturnVal = '';
            var IsClear = true;
            if ($(jDivObj).val() && $(jDivObj).attr("filter-IndexValue")) {
                if (filterID != null && filterID != undefined) {
                    ReturnVal = filterName + "=" + filterID;
                    IsClear = false;
                }

            }
            if (IsClear) {
                ClearfilterAttr(jDivObj);
            }
            return ReturnVal;
        }

        function ClearfilterAttr(jDivObj) {
            $(jDivObj).attr('filter-IndexId', '');
            $(jDivObj).attr('filter-IndexName', '');
            $(jDivObj).attr('filter-IndexValue', '');
        }

        var getServiceUrl = function (pagenum, groupName) {

            var url = "/api/custom/eventlisting/execute?";
            var query = [];

            if (plugin_event.searchString != null && plugin_event.searchString != "") {
                query.push("searchstring=" + encodeURIComponent(plugin_event.searchString));
            }
            if ($('.chkAll').prop("checked")) {
                query.push("alleventcheck=true");
            }
            else {
                query.push("alleventcheck=false");
            }

            if (plugin_event.eventLocations != null && plugin_event.eventLocations != "") {
                query.push("eventlocationsml_t=" + plugin_event.eventLocations);
            }


            if (plugin_event.startDate != null && plugin_event.startDate != "") {
                query.push($("#start_date-search").data("category") + '=' + plugin_event.startDate);
            }

            if (plugin_event.endDate != null && plugin_event.endDate != "") {
                query.push($("#end_date-search").data("category") + '=' + plugin_event.endDate);
            }


            if (plugin_event.filterName != null && plugin_event.filterName != ""
                && plugin_event.filterId != null && plugin_event.filterId != "") {
                query.push(plugin_event.filterName + "=" + plugin_event.filterId);
            }
            else if (plugin_event.filterValue != null && plugin_event.filterValue != "") {
                query.push("practice_name=" + encodeURIComponent(plugin_event.filterValue));
            }


            if (plugin_event.loadUpcomingEvents != null && plugin_event.loadUpcomingEvents == "true") {
                query.push("upcomingdate=f1");
            }


            if (plugin_event.pageNum != null && plugin_event.pageNum != "") {
                query.push("pagenum=" + plugin_event.pageNum);
            }

            if (plugin_event.pagingInfo.isLastClick) {
                query.push("isLastClick=true");
                query.push("pagingLastCount=" + plugin_event.pagingInfo.pagingLastCount);
            }

            if (plugin_event.pagingInfo.isFirstClick) {
                query.push("isFirstClick=true");
                query.push("pagingFirstCount=" + plugin_event.pagingInfo.pagingFirstCount);
            }

            if (plugin_event.pagingInfo.isNextClick) {
                query.push("isNextClick=true");
                query.push("pagingNextCount=" + plugin_event.pagingInfo.pagingNextCount);
            }

            if (plugin_event.pagingInfo.isPreviousClick) {
                query.push("isPreviousClick=true");
                query.push("pagingPreviousCount=" + plugin_event.pagingInfo.pagingPreviousCount);
            }

            var isFor_CurrentFutureTab = getActiveTabViaQS() == "current-future";
            var append = isFor_CurrentFutureTab ? "" : "_2";

            // New Events Page
            if (isFor_CurrentFutureTab) {
                query.push("isactivetab_currentfuture=true");
            }
            else {
                query.push("isactivetab_currentfuture=false");
            }

            ////for sorting
            var sortingValue = $('#custom4' + append).val();
            if (sortingValue != null && sortingValue != undefined) {
                query.push("SortingField=" + sortingValue);

            }

            ////for page size
            var pageSizeValue = $('#oldSelectedPageSize' + append).val();
            if (pageSizeValue != null && pageSizeValue != undefined) {
                query.push("pagesize=" + pageSizeValue);

            }

            return url + query.join("&") + "&" + getFilterValues();
        };


        var getPageUrl = function (loadupcomingevents, pagenum) {


            var searchString = '';

            var url = plugin_event.settings.pageUrl + "?";
            var query = [];

            //alert("4");
            if (loadupcomingevents != null) {

                query.push("loadupcomingevents=" + loadupcomingevents);
            }

            if (plugin_event.isResetFields == true) {
                query.push("resetFields=" + true);
            }

            if ($('#event-search') != null && $('#event-search').val() != null
                && plugin_event.settings.keywordDefaultValue != null
                && $('#event-search').val().toLowerCase() != plugin_event.settings.keywordDefaultValue.toLowerCase()) {
                searchString = $('#event-search').val();
                if (searchString && searchString != '') {
                    query.push("searchstring=" + encodeURIComponent(searchString));
                }

            }

            if ($('#event-location-search').val()) {
                var locationValue = $('#event-location-search').val();
                if (locationValue != "" && plugin_event.settings.locationDefaultValue != null && locationValue.toLowerCase() != plugin_event.settings.locationDefaultValue.toLowerCase()) {
                    query.push("eventlocations=" + encodeURIComponent(locationValue));
                }
            }

            if ($("#start_date-search").val() != '' && $("#start_date-search").val().toLowerCase() != $("#start_date-search").data('defaultvalue').toLowerCase()) {
                query.push("start_date=" + $("#start_date-search").val());
            }


            if ($("#end_date-search").val() != '' && $("#end_date-search").val().toLowerCase() != $("#end_date-search").data('defaultvalue').toLowerCase()) {
                query.push("end_date=" + $("#end_date-search").val());
            }

            var filterId = $("#event-practice-search").attr('filter-IndexId');
            var filterName = $("#event-practice-search").attr('filter-indexname');
            var practiceValue = $.trim($("#event-practice-search").val());
            //plugin_event.settings.practiceDefaultValue
            var indexValue = $("#event-practice-search").attr("filter-IndexValue");
            if (indexValue != null && indexValue == $.trim($("#event-practice-search").val())) {
                if ($("#event-practice-search").val() && $("#event-practice-search").attr("filter-IndexValue")) {
                    if (filterId != null && filterId != "" && filterName != null && filterName != "") {
                        query.push("filtername=" + filterName);
                        query.push("filterid=" + filterId);


                    }

                }
            }

            if (practiceValue != null && practiceValue.toLowerCase() != plugin_event.settings.practiceDefaultValue.toLowerCase()) {
                query.push("filtervalue=" + encodeURIComponent($("#event-practice-search").val()));
            }

            query.push("pagenum=" + pagenum);


            plugin_event.pagingInfo.isLoadPagination = true;

            if (plugin_event.pagingInfo.isLastClick) {
                query.push("isLastClick=true");
                query.push("pagingLastCount=" + plugin_event.pagingInfo.pagingLastCount);
            }

            if (plugin_event.pagingInfo.isFirstClick) {
                query.push("isFirstClick=true");
                query.push("pagingFirstCount=" + plugin_event.pagingInfo.pagingFirstCount);
            }

            if (plugin_event.pagingInfo.isNextClick) {
                query.push("isNextClick=true");
                query.push("pagingNextCount=" + plugin_event.pagingInfo.pagingNextCount);
            }

            if (plugin_event.pagingInfo.isPreviousClick) {
                query.push("isPreviousClick=true");
                query.push("pagingPreviousCount=" + plugin_event.pagingInfo.pagingPreviousCount);
            }

            var isFor_CurrentFutureTab = getActiveTabViaUI() == "current-future";
            var append = isFor_CurrentFutureTab ? "" : "_2";

            // New Events Page
            if (isFor_CurrentFutureTab) {
                query.push("isactivetab_currentfuture=true");
            }
            else { // past
                query.push("isactivetab_currentfuture=false");
            }

            ////for sorting
            var sortingValue = $('#custom4' + append).val();
            if (sortingValue != null && sortingValue != undefined) {
                query.push("SortingField=" + sortingValue);

            }

            ////for page size
            var pageSizeValue = $('#oldSelectedPageSize' + append).val();
            if (pageSizeValue != null && pageSizeValue != undefined) {
                query.push("pagesize=" + pageSizeValue);

            }

            if (plugin_event.isFewerOptionOpen != null && plugin_event.isFewerOptionOpen == "true") {
                query.push("openfeweroptions=true");
            }
            if ($('.chkAll').prop("checked")) {
                query.push("alleventcheck=true");
            }
            else {
                query.push("alleventcheck=false");
            }

            //alert(url + query.join("&") + "&" + getFilterValues());
            return url + query.join("&") + "&" + getFilterValues();
        };

        // New Events Page
        /* get the active tab via query string */
        var getActiveTabViaQS = function () {
            if (getParamValueFrmKey("isactivetab_currentfuture") == "false") {
                return "past";
            }
            else {
                return "current-future";
            }
        };

        // New Events Page
        /* get the currently selected tab in the page */
        var getActiveTabViaUI = function () {
            return $("#event-search-tabs .pub-search-area:visible").data("value");
        };


        /* retrieve filter values */
        var getFilterValues = function () {
            var filters = {};
            $('.checkbox1').each(function () {
                if ($(this).prop("checked")) {
                    if (!filters[this.name]) {
                        filters[this.name] = [];
                    }
                    filters[this.name].push($.trim($(this).val()));
                }
            });
            flatendedValues = [];
            for (var filterName in filters) {
                flatendedValues.push(filterName + "=" + filters[filterName].join(","));
            }
            if ($('.chkAll').prop("checked")) {
                flatendedValues.push('alleventcheck=true');
            }
            if ($('.chkAll').prop("checked")) {
                flatendedValues.push('alleventcheck=true');
            }
            if ($('#clecheckbox').prop("checked")) {
                $('#clecheckbox').parent().css("background-position", "center -40px");
                flatendedValues.push('param_is__cle_event_b=true');
            }
            return flatendedValues.join("&");
        }

        plugin_event.init();
    };

    /* add the plugin to the jQuery.fn object */
    $.fn.eventSearch = function (options) {
        return this.each(function () {
            if (undefined == $(this).data('eventSearch')) {
                var plugin_event = new $.eventSearch(this, options);
                $(this).data('eventSearch', plugin_event);

            }
        });
    }

    $.autoSuggest_event = function (element, options) {
        /* defaults */
        var defaults = {
            itemId: "",
            listUrl: "",
            listType: ""

        };
        var $element = $(element), element = element;
        var plugin_event = this;
        plugin_event.settings = {}

        /* init function */
        plugin_event.init = function () {
            // clearAutoSuggestBox();
            plugin_event.settings = $.extend({}, defaults, options);

            if (plugin_event.settings.listType == "completelistEvent") {
                if ($element.completelistEvent) {
                    $element.completelistEvent({
                        source: function (request, response) {
                            $.getJSON(plugin_event.settings.listUrl, { currentTime: $.now(), term: request.term },
                                response);
                        },
                        minLength: 3,
                        select: function (event, ui) {
                            decodeURIComponent($(this).val(ui.item.FilterName));
                            return false;
                        },
                        search: function () {
                            $('ul.ui-autocomplete').scrollTop(0);
                        },
                        open: function () {
                            $('ul.ui-autocomplete').scrollTop(0);
                        }
                    });

                }
            }

            if (plugin_event.settings.listType == "completelistPractice") {
                if ($element.completelistPractice) {
                    $element.completelistPractice({
                        source: plugin_event.settings.listUrl,
                        minLength: 3,
                        select: function (event, ui) {
                            $(this).val(decodeURIComponent(ui.item.FilterName));
                            $(this).attr("filter-IndexId", ui.item.FilterID);
                            $(this).attr("filter-IndexName", ui.item.FilterIndexName);
                            $(this).attr("filter-IndexValue", decodeURIComponent(ui.item.FilterName));
                            return false;
                        },
                        search: function () {
                            $('ul.ui-autocomplete').scrollTop(0);
                        },
                        open: function () {
                            $('ul.ui-autocomplete').scrollTop(0);
                        }
                    });

                }
            }

            if (plugin_event.settings.listType == "completelistLocation") {
                if ($element.completelistLocation) {
                    $element.completelistLocation({
                        source: plugin_event.settings.listUrl,
                        minLength: 3,
                        select: function (event, ui) {
                            $(this).val(ui.item.FilterName);
                            $(this).attr("filter-IndexId", ui.item.FilterID);
                            $(this).attr("filter-IndexName", ui.item.FilterIndexName);
                            $(this).attr("filter-IndexValue", ui.item.FilterName);
                            return false;
                        },
                        search: function () {
                            $('ul.ui-autocomplete').scrollTop(0);
                        },
                        open: function () {
                            $('ul.ui-autocomplete').scrollTop(0);
                        }
                    });

                }
            }

            if (plugin_event.settings.listType == "completelistLocationEvent") {
                if ($element.completelistLocationEvent) {
                    $element.completelistLocationEvent({
                        source: plugin_event.settings.listUrl,
                        minLength: 3,
                        select: function (event, ui) {
                            $(this).val(ui.item.FilterName);

                            return false;
                        },
                        search: function () {
                            $('ul.ui-autocomplete').scrollTop(0);
                        },
                        open: function () {
                            $('ul.ui-autocomplete').scrollTop(0);
                        }
                    });

                }
            }

        }
        plugin_event.init();
    };

    /* add the plugin to the jQuery.fn object */
    $.fn.autoSuggest_event = function (options) {
        return this.each(function () {
            if (undefined == $(this).data('autoSuggest')) {
                var plugin_event = new $.autoSuggest_event(this, options);
                $(this).data('autoSuggest', plugin_event);
            }
        });
    }

}(jQuery));

function ClearEventFields() {
    //event-location-search
    $("#event-search").val($('#event-search').data('defaultvalue'));
    $("#event-location-search").val($('#event-location-search').data('defaultvalue'));
    $("#event-practice-search").val($("#event-practice-search").data('defaultvalue'));
    $('#start_date-search').val($('#start_date-search').data('defaultvalue'));
    $('#end_date-search').val($('#end_date-search').data('defaultvalue'));
    $("#end_date-search").datepicker("option", "minDate", null);
    $("#start_date-search").datepicker("option", "maxDate", null);
    allCheckBoxChange(1);
    $('#clecheckbox').removeAttr("checked");
    $('#clecheckbox').parent().css("background-position", "center 0px");

}